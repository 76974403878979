import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router'
import { createHashHistory, createBrowserHistory } from 'history';
import users from './users';
import profile from './profile';
// import modal from './modalConductor';
// import topics from './topics';
// import messages from './messages';
// const history = createBrowserHistory()

const rootReducer =(history) => combineReducers({
  router: connectRouter(history),
  profile,
  users
  // modal,
  // topics,
  // messages
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
