import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import createRootReducer from './reducers'
import thunk from 'redux-thunk';

export const history = createBrowserHistory()

export default function configureStore(preloadedState?: any) :any{
  const composeEnhancer: typeof compose = compose;
  const store = createStore(
    createRootReducer(history),
    preloadedState,
    composeEnhancer(
      applyMiddleware(
        routerMiddleware(history),
        thunk
      ),
    ),
  )

  // // Hot reloading
  // if (module.hot) {
  //   // Enable Webpack hot module replacement for reducers
  //   module.hot.accept('./reducers', () => {
  //     store.replaceReducer(createRootReducer(history));
  //   });
  // }

  return store
}

/*
export default function configureAppStore(preloadedState: any) {
  
const routeMiddleware = routerMiddleware(history)


  const store = configureStore({
    reducer: rootReducer(history),
    // middleware: [loggerMiddleware, ...getDefaultMiddleware()],
    middleware: [...getDefaultMiddleware(),routeMiddleware],
    preloadedState,
    //enhancers: [monitorReducersEnhancer]
  });

  // if (process.env.NODE_ENV !== 'production' && module.hot) {
    // module.hot.accept('./reducers', () => store.replaceReducer(rootReducer));
  // }

  return store;
}
*/