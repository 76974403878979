/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getVersion = /* GraphQL */ `
  mutation GetVersion($app: String) {
    getVersion(app: $app)
  }
`;
export const inviteUser = /* GraphQL */ `
  mutation InviteUser($input: InviteUserInput!) {
    inviteUser(input: $input) {
      id
      organizationId
      email
      role
      createdAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
      organizationId
      email
      role
      createdAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      organizationId
      email
      role
      createdAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      id
      organizationId
      email
      role
      createdAt
    }
  }
`;
