import React from 'react';
import ReactDOM from 'react-dom';
import { Provider, TypedUseSelectorHook, useSelector } from 'react-redux';
// import { ThemeProvider } from '@material-ui/core/styles';
import App from './App';
import initAmplify from './Amplify/amplifyConfig';
import configureStore,{history} from './store/configureStore';
// import theme from './theme';
import { dom } from '@fortawesome/fontawesome-svg-core';
import reportWebVitals from './reportWebVitals';
import Router from './router'
import { createHashHistory, createBrowserHistory } from 'history'
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
// import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import "styles/global.scss";

import * as aws_amplify_react from "aws-amplify-react";
import AmplifyCustomUi from "aws-amplify-react-custom-ui";

Amplify.configure(awsconfig);
// Amplify.configure({
//   API: {
//     graphql_headers: async () => {
//     const session = await await Amplify.Auth.currentSession()
//     return ({
//         'Authorization': session.getIdToken().getJwtToken()
//     })}
//   }
// });
// AmplifyCustomUi.configure(aws_amplify_react);

// const amplifyConfig = {
//   cognitoRegion: process.env.REACT_APP_COGNITO_REGION || '',
//   cognitoUserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID || '',
//   cognitoUserPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID || '',
// };
 
// initAmplify(amplifyConfig);

export const store = configureStore({});
export type RootState = ReturnType<typeof store.getState>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

dom.watch();


ReactDOM.render(
  <Provider store={store}>
    {/* <ThemeProvider theme={theme}> */}
    <Router history={history} />
    {/* </ThemeProvider> */} 
  </Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
