import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { withRouter, Link } from 'react-router-dom';
import classNames from 'classnames'
import { withAuthenticator, SignIn, SignUp, ConfirmSignUp, ConfirmSignIn, VerifyContact, ForgotPassword, RequireNewPassword } from "aws-amplify-react";
import amplifyCognitoTheme from 'config/amplifyCognitoTheme';
// import CustomHeader from "components/Header";
import { Layout, Menu, Breadcrumb } from 'antd';
import {
  UserOutlined, LaptopOutlined, SettingOutlined, ShopOutlined,
  NotificationOutlined, PieChartOutlined, DesktopOutlined, SolutionOutlined,
  TeamOutlined, ShoppingCartOutlined, ShoppingOutlined, AccountBookOutlined, ControlOutlined
} from '@ant-design/icons';
import TopBar from 'components/layout/TopBar';
import styles from './style.module.scss'
import Logo from 'assets/logo.png';
import Auth from '@aws-amplify/auth';
import * as AntdIcons from '@ant-design/icons';
import MenuData from '../../MenuData';
import { RootState } from 'index';

const { SubMenu } = Menu;
const { Header, Content, Sider, Footer } = Layout;


// import TopBar from '@vb/components/TopBar';
// import Breadcrumbs from '@vb/components/Breadcrumbs'
// import Breadcrumbs2 from '@vb/components/Breadcrumbs2'
// import MenuClassic from '@vb/components/MenuClassic'
// import MenuFlyout from '@vb/components/MenuFlyout'
// import MenuSimply from '@vb/components/MenuSimply'
// import Footer from '@vb/components/Footer'
// import Footer2 from '@vb/components/Footer2'
// import Footer3 from '@vb/components/Footer3'
// import Footer4 from '@vb/components/Footer4'
// import Sidebar from '@vb/components/Sidebar'
// import SupportChat from '@vb/components/SupportChat'
// import Variants from '@vb/components/Variants'
// import Tutorial from '@vb/components/Tutorial'

const mapStateToProps = ({ settings }) => ({
  isMobileMenuOpen: settings.isMobileMenuOpen,
  isContentMaxWidth: settings.isContentMaxWidth,
  isAppMaxWidth: settings.isAppMaxWidth,
  isGrayBackground: settings.isGrayBackground,
  isSquaredBorders: settings.isSquaredBorders,
  isCardShadow: settings.isCardShadow,
  isBorderless: settings.isBorderless,
  isTopbarFixed: settings.isTopbarFixed,
  isTopbarSeparated: settings.isTopbarSeparated,
  isGrayTopbar: settings.isGrayTopbar,
  layoutTopbar: settings.layoutTopbar,
  layoutBreadcrumbs: settings.layoutBreadcrumbs,
  layoutFooter: settings.layoutFooter,
  layoutMenu: settings.layoutMenu,
})

// let touchStartPrev = 0
// let touchStartLocked = false

const MainLayout: React.FC = ({
  // dispatch,
  children,
  // isMobileMenuOpen,
  // isContentMaxWidth,
  // isAppMaxWidth,
  // isGrayBackground,
  // isSquaredBorders,
  // isCardShadow,
  // isBorderless,
  // isTopbarFixed,
  // isTopbarSeparated,
  // isGrayTopbar,
  // layoutTopbar,
  // layoutBreadcrumbs,
  // layoutFooter,
  // layoutMenu,
}) => {
  const { profile } = useSelector((state: RootState) => ({
    profile: state.profile
  }));
  console.log('USER', profile);

  const menu = MenuData();
  const menuItemRender = (menuItem) => {
    const AntdIcon = menuItem.icon ? AntdIcons[menuItem.icon] : undefined;
    return (
      <Menu.Item key="1" icon={AntdIcon ? <AntdIcon /> : undefined} >
        <Link to={menuItem.link}>{menuItem.title}</Link>
      </Menu.Item>
    );
  }
  const subMenuItemRender = (menuItem) => {
    const AntdIcon = menuItem.icon ? AntdIcons[menuItem.icon] : undefined;
    return (
      <SubMenu key={menuItem.key} icon={AntdIcon ? <AntdIcon /> : undefined} title={menuItem.title}>
        { menuItem.children.map(subMenu =>
          <Menu.Item key={subMenu.key} >
            <Link to="/users">{subMenu.title}</Link>
          </Menu.Item>
        )}
      </SubMenu>
    )
  }
  const applicationsMenu = (menuItem) => {
    const AntdIcon = menuItem.icon ? AntdIcons[menuItem.icon] : undefined;
    const Applications = [{
      title: 'TCA',
      key: 'applications/tca',
      link: '/tca/'
    }, {
      title: 'APP-2',
      key: 'applications/app-2',
      link: ''
    }];

    return (
      <SubMenu key={menuItem.key} icon={AntdIcon ? <AntdIcon /> : undefined} title={menuItem.title}>
        { Applications.map(subMenu =>
          <Menu.Item key={subMenu.key} >
            {/* <Link to={subMenu.link}>{subMenu.title}</Link> */}
            <a href={subMenu.link} target="_blank">{subMenu.title}</a>
          </Menu.Item>
        )}
      </SubMenu>
    );
  }

  // <SubMenu key="2" icon={<TeamOutlined />} title="Applications">
  //   <Menu.Item key="2-1" >TCA</Menu.Item>
  //   <Menu.Item key="2-1" >APP-2</Menu.Item>
  // </SubMenu>
  // <SubMenu key="3" icon={<TeamOutlined />} title="User Management">
  //   <Menu.Item key="3-1">Users</Menu.Item>
  //   <Menu.Item key="3-2">Role Management</Menu.Item>
  // </SubMenu>
  // <SubMenu key="4" icon={<TeamOutlined />} title="Account Management">
  //   <Menu.Item key="4-1">Account Profile</Menu.Item>
  //   <Menu.Item key="4-2">Billing</Menu.Item>
  //   <Menu.Item key="4-2">Reports</Menu.Item>
  // </SubMenu>

  return (
    <div
      className={classNames({
        // vb__layout__grayBackground: isGrayBackground,
      })}
    >
      <Layout style={{ minHeight: '100vh' }}>
        {/* <Sider collapsible collapsed={collapsed} onCollapse={this.onCollapse}> */}
        <Sider collapsible width="300">
          {/* <div className={styles.logo} > */}
          <div className={classNames("p-3 d-flex align-items-end mt-auto logo", styles.logo)}>
            <img src={Logo} />
            <div className={classNames(styles.logo__name)}>AppTax</div>
          </div>
          <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
            {menu.map(menuItem => {
              if (menuItem.roles && !menuItem.roles.includes(profile.role[0])) {
                return null
              }
              ///start - check for application submenu due to dynamic data based on the subscription
              if (menuItem.key === 'applications') {
                return applicationsMenu(menuItem);
              }
              ///end - check for application submenu due to dynamic data based on the subscription
              if (menuItem.children) {
                return subMenuItemRender(menuItem)
              }
              return menuItemRender(menuItem)
            })
            }
            {/* <Menu.Item key="9" >
              Files
            </Menu.Item> */}
          </Menu>
        </Sider>
        <Layout className="site-layout">
          <Header className={styles.header} >
            <TopBar></TopBar>
          </Header>

          <Content style={{ margin: '0 16px' }}>
            <Breadcrumb style={{ margin: '16px 24px', }}>
              <Breadcrumb.Item>User</Breadcrumb.Item>
              <Breadcrumb.Item>Bill</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background" style={{ padding: '16px 24px', minHeight: 360 }}>
              {children}
            </div>
          </Content>
          <Footer style={{ textAlign: 'center' }}>Created by AppTax. ©2021 </Footer>
        </Layout>
      </Layout>

    </div>
  )
}
export default withAuthenticator(MainLayout, false, [
  <SignIn />,
  <ConfirmSignIn />,
  <VerifyContact />,
  <SignUp />,
  <ConfirmSignUp />,
  <ForgotPassword />,
  <RequireNewPassword />
], null, amplifyCognitoTheme);
// export default withRouter(connect(mapStateToProps)(MainLayout))
