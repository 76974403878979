/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const testQuery = /* GraphQL */ `
  query TestQuery {
    testQuery {
      _
    }
  }
`;
export const lambda = /* GraphQL */ `
  query Lambda($limit: Int) {
    lambda(limit: $limit) {
      authorized
      organization
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organizationId
        email
        role
        createdAt
      }
      nextToken
    }
  }
`;
