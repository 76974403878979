/* global wp */
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { listUsers } from "../../graphql/queries";
import { ListUsersQuery, InviteUserMutation, InviteUserInput } from "../../API";
import callGraphQL, { subscribeGraphQL, GraphQLOptions } from "../../models/graphql-api";
import User, {
  mapListUsersQuery
} from "../../models/user";
import { inviteUser } from 'graphql/mutations';
import { store } from 'index';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import * as _ from "lodash"; 

interface UserState {
  items: any;
  loading: boolean;
  error: string;
  nextToken: any;
  // pagination: {
    // current: number;
    // totalCount: any,
    // pageSize: number
  // }
}


const initialState: UserState = {
  items: [],
  loading: false,
  error: '',
  nextToken: null
  // pagination: {
  //   current: 1,
  //   totalCount: null,
  //   pageSize: 10
  // },
};


export interface GraphQLAPIOptions {
  filter?: object,
  nextToken?: any
  // current: number, 
  // orderBy?: object
  // input?: object;
  // variables?: object;
  // authMode?: GRAPHQL_AUTH_MODE;
}
export interface GraphQLAPIOptions {
  filter?: object,
  nextToken?: any
  // current: number, 
  // orderBy?: object
  // input?: object;
  // variables?: object;
  // authMode?: GRAPHQL_AUTH_MODE;
}
export const listUsersFn = async (params: GraphQLAPIOptions) => {
  console.log('in listUsers',params);
  const usersData = await callGraphQL<ListUsersQuery>(listUsers,{
    filter:params.filter,
    nextToken: params.nextToken
    // orderBy: params.orderBy,
    // limit: initialState.pagination.pageSize,//params.limit,
    // offset: (params.current -1) * initialState.pagination.pageSize
  });
  console.log('userData',usersData);
  const users = mapListUsersQuery(usersData);
  console.log('response from listMessages: ', users);
  return users;
};

const inviteUserFn = async (params: InviteUserInput) => {
  console.log('inviteuser params',params);
  const usersData = await callGraphQL<InviteUserMutation>(inviteUser,{
    input: {...params}
  });
  console.log('userData',usersData.data?.inviteUser);
  return usersData.data?.inviteUser;
};

export const listUsersAction = createAsyncThunk('users/listUsers', listUsersFn);
export const inviteUserAction = createAsyncThunk('users/inviteUser', inviteUserFn);

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: builder => {
    // you can mutate state directly, since it is using immer behind the scenes\
    builder
      .addCase(listUsersAction.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(listUsersAction.fulfilled, (state, action) => {
        console.log('action: ', action);
        state.items = action.payload ? action.payload.items : null;
        // state.pagination = {
        //   ...state.pagination,
        //   totalCount: action.payload ? action.payload.totalCount : null
        // }
        state.nextToken = action.payload ? action.payload.nextToken: null;
        state.loading = false;
      })
      // create
      .addCase(inviteUserAction.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(inviteUserAction.fulfilled, (state, action) => {
        console.log('action: inviteUserAction', action);
        state.items = [ action.payload , ...state.items ];
        state.loading = false;
      })
    }
});

//export const { getMyselfAction, getUserAction } = counterSlice.actions;
export default userSlice.reducer;
