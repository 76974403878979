/* global wp */
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
// import { listCompanys } from "../../graphql/queries";
// import { ListCompanysQuery, CreateCompanyMutation, CreateCompanyInput, DeleteCompanyInput, DeleteCompanyMutation, UpdateCompanyInput, UpdateCompanyMutation } from "../../API";
import callGraphQL, { subscribeGraphQL, GraphQLOptions } from "../../models/graphql-api";
// import User, {
//   mapListCompanysQuery
// } from "../../models/company";
// import { createCompany, deleteCompany, updateCompany } from 'graphql/mutations';
import { store } from 'index';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import * as _ from "lodash"; 

interface ProfileState {
  email: string | undefined;
  organization: string | undefined;
  organizationId: string | undefined;
  role: string[];
  error: string | undefined;
}


const initialState: ProfileState = {
  email: '',
  organization: undefined,
  organizationId: undefined,
  role: [],
  error: undefined
};



const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfile: ( state, action )=>{
      console.log('in setProfile',action);
      const { organization, organizationId, role, email } = action.payload;
      state.email = email;
      state.organization = organization;
      state.organizationId = organizationId;
      state.role = typeof role === 'string' ? JSON.parse(role) : role;
    }
  }
});

//export const { getMyselfAction, getUserAction } = counterSlice.actions;
export default profileSlice.reducer;
