/*
 * Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

const bg = '/src/assets/bg.png';

const primaryColor ='#008d3c';
const secondaryColor = '#ffb711';
export const Container = {
  //'backgroundImage': 'url('+ bg +')',
  'backgroundColor':primaryColor,
  'backgroundRepeat': 'repeat',
  'backgroundSize': 'cover'
};
export const FormContainer = {
  'backgroundColor': primaryColor,//'#F9F9F9',
  'borderColor': primaryColor, //'#F9F9F9',
  'border': 'none',
  'height': '100vh',
  'margin': '0px',
  'padding': '30px 0'
};
export const FormSection = {
  'backgroundColor': '#FFFFFF',
  'borderColor': '#FFFFFF',
  'borderRadius': '0px',
  'boxShadow': '0 .125rem .25rem rgba(0,0,0,.075)',
  'margin': '0',
  'top': '50%',
  'transform': 'translateY(-50%)'
};
export const FormField = {};
export const SectionHeader = {
  'textAlign': 'center',
  'fontSize': '24px',
  'fontWeight': '600'
};
export const SectionBody = {};
export const SectionFooter = {};
export const SectionFooterPrimaryContent = {
//   'width': '100%'
};
export const SectionFooterSecondaryContent = {
//   'display': 'none'
};
export const Input = {
  'borderRadius': '0px',
  'borderRight': '0px',
  'borderLeft': '0px',
  'borderTop': '0px',
};
export const Button = {
//   'width': '100%',
  'backgroundColor': secondaryColor//'#005078'
};
export const PhotoPickerButton = {};
export const PhotoPlaceholder = {};
export const SignInButton = {

};
export const SignUpButton = {
	
};
export const SignInButtonIcon = {};
export const SignInButtonContent = {};
export const SignUpButtonIcon = {};
export const SignUpButtonContent = {};
export const Strike = {};
export const StrikeContent = {};
export const ActionRow = {};
export const FormRow = {};
export const A = {};
export const Hint = {
  'marginTop': '20px',
  'marginBottom': '80px'
};
export const Radio = {};
export const Label = {};
export const InputLabel = {
  'display': 'none'
};
export const AmazonSignInButton = {};
export const FacebookSignInButton = {};
export const GoogleSignInButton = {};
export const OAuthSignInButton = {};
export const Toast = {};
export const NavBar = {};
export const NavRight = {};
export const Nav = {};
export const NavItem = {};
export const NavButton = {};

const AmplifyCognitoTheme = {
	container: Container,
	formContainer: FormContainer,
	formSection: FormSection,
	formField: FormField,

	sectionHeader: SectionHeader,
	sectionBody: SectionBody,
	sectionFooter: SectionFooter,
	sectionFooterPrimaryContent: SectionFooterPrimaryContent,
	sectionFooterSecondaryContent: SectionFooterSecondaryContent,

	input: Input,
	button: Button,
	photoPickerButton: PhotoPickerButton,
	photoPlaceholder: PhotoPlaceholder,
	signInButton: SignInButton,
	signUpButton: SignUpButton,
	signInButtonIcon: SignInButtonIcon,
	signInButtonContent: SignInButtonContent,
	signUpButtonIcon: SignUpButtonIcon,
	signUpButtonContent: SignUpButtonContent,

	amazonSignInButton: AmazonSignInButton,
	facebookSignInButton: FacebookSignInButton,
	googleSignInButton: GoogleSignInButton,
	oAuthSignInButton: OAuthSignInButton,

	formRow: FormRow,
	strike: Strike,
	strikeContent: StrikeContent,
	actionRow: ActionRow,
	a: A,

	hint: Hint,
	radio: Radio,
	label: Label,
	inputLabel: InputLabel,
	toast: Toast,

	navBar: NavBar,
	nav: Nav,
	navRight: NavRight,
	navItem: NavItem,
	navButton: NavButton,
};

export default AmplifyCognitoTheme;
