import React from 'react'
import { connect } from 'react-redux'
import { Layout } from 'antd'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
// import { withTranslation } from 'react-i18next'
// import Sidebar from '@airui/layout/Sidebar'
// import RTLContext from 'localizationConfig';
// import LanguageSwitcher from '@airui/layout/TopBar/LanguageSwitcher';
import style from './style.module.scss'
// import topBarStyle from '@airui/layout/TopBar/style.module.scss';
import AmplifyAuth from 'components/Auth/AmplifyAuthenticator';

import logo from 'assets/logo.png';



const mapStateToProps = ({ settings }) => ({
  isGrayTopbar: settings.isGrayTopbar,
  isCardShadow: settings.isCardShadow,
  isSquaredBorders: settings.isSquaredBorders,
  isBorderless: settings.isBorderless,
  authPagesColor: settings.authPagesColor,
  logo: settings.logo,
  description: settings.description,
})

const AuthLayout = ({
  // children,
  // isCardShadow,
  // isSquaredBorders,
  // isBorderless,
  // authPagesColor,
  // logo,
  // description,
  // t
}) => {
  let  /*children,*/isCardShadow:any,isSquaredBorders:any,isBorderless:any,authPagesColor:any;
  const direction = 'rtl'; //React.useContext(RTLContext)
  return (
    <Layout>
      <Layout.Content>
        {/* <Sidebar /> */}
        <div
          className={classNames(`${style.container}`, {
            air__layout__squaredBorders: isSquaredBorders,
            air__layout__cardsShadow: isCardShadow,
            air__layout__borderless: isBorderless,
            [style.white]: authPagesColor === 'white',
            [style.gray]: authPagesColor === 'gray',
          })}
          style={{
            backgroundImage:
              authPagesColor === 'image' ? 'url(resources/images/content/photos/8.jpeg)' : '',
          }}
        >
          {/* <div className={classNames(topBarStyle.topbar,"container-fluid")}> */}
          <div className={classNames("container-fluid")}>
            <div className={classNames("d-none d-sm-block", "mr-4")}>
              {/* <LanguageSwitcher /> */}
            </div>
          </div>
          <div className="pt-5 pb-5 d-flex align-items-end mt-auto">
            <a href="#" onClick={e => e.preventDefault()} className={style.logo}>
              {/* <div className={style.logo__letter}>A</div> */}
              {/* <div className={style.logo__name}>{logo}</div> */}
              <img className={style.logo} src={logo} alt={"Logo alt"} />
              {/* <div className={style.logo__descr}>{description}</div> */}
            </a>
          </div>
          {/* <div className={style.containerInner}>{children}</div> */}
          <AmplifyAuth></AmplifyAuth>
          <div className="mt-auto pb-5 pt-5">
            <ul
              className={`${style.footerNav} list-unstyled d-flex mb-2 flex-wrap justify-content-center`}
            >
              <li>
                <a href="#" onClick={e => e.preventDefault()}>
                  {/* {t('auth.login.footer.career')} */}
                  Career 
                </a>
              </li>
              <li>
                <a href="#" onClick={e => e.preventDefault()}>
                  {/* {t('auth.login.footer.support')} */}
                  Support 
                </a>
              </li>
              <li>
                <a href="#" onClick={e => e.preventDefault()}>
                  {/* {t('auth.login.footer.contactUs')} */}
                  Contact us
                </a>
              </li>
            </ul>
            <div className="text-gray-4 text-center">&copy; 2021 AppTax.io LLC</div>
          </div>
        </div>
      </Layout.Content>
    </Layout>
  )
}

// export default withTranslation(['component'])(withRouter(connect(mapStateToProps)(AuthLayout)))
export default AuthLayout;
