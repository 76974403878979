import React, { useEffect, useState } from 'react'
// import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { UserOutlined } from '@ant-design/icons'
import { Menu, Dropdown, Avatar, Badge } from 'antd'
import styles from './style.module.scss'
import { Auth } from 'aws-amplify';
import { AmplifySignOut } from '@aws-amplify/ui-react';

const mapStateToProps = ({ profile }) => ({ profile })

type Props = {
  profile: any
};

const ProfileMenu: React.FC<Props> = (props: Props) => {
  
  

  const [count, setCount] = useState(7)
  const logout = async (e) => {
    try {
      await Auth.signOut({ global: true });
    } catch (error) {
        console.log('error signing out: ', error);
    }
  }
  console.log('user in menu',props.profile);

  const addCount = () => {
    // setCount(count + 1)
  }

  const menu = (
    <Menu selectable={false}>
      <Menu.Item>
        Hello,<strong>{props?.profile?.organization || 'Anonymous'} </strong> member
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <div>
          <strong>
            Email:{' '}
          </strong>
          {props?.profile?.email || '—'}
          <br />
          {/* <strong>
            Phone:{' '}
          </strong>
          {props?.user?.phone || '—'} */}
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <a href="#" onClick={e => e.preventDefault()}>
          <i className={`${styles.menuIcon} fe fe-user`} />
          Edit profile
        </a>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <AmplifySignOut buttonText="Logout"></AmplifySignOut>
        {/* <a href="#" onClick={logout}>
          <i className={`${styles.menuIcon} fe fe-log-out`} />
          Logout
        </a> */}
      </Menu.Item>
    </Menu>
  )
  return (
    <Dropdown overlay={menu} trigger={['click']} onVisibleChange={addCount}>
      <div className={styles.dropdown}>
        <Badge count={count}>
          <Avatar className={styles.avatar} shape="square" size="large" icon={<UserOutlined />} />
        </Badge>
      </div>
    </Dropdown>
  )
}

export default connect(mapStateToProps)(ProfileMenu)
