import React, { Suspense, lazy } from 'react'
import { BrowserRouter, Route, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import Switch from 'react-router-transition-switch'
import { connect } from 'react-redux'
import Layout from './layouts'; 
import { Spin } from 'antd';
import NotFoundPage from './pages/system/404'
import {   History } from 'history'
import configureStore from './store/configureStore';

const routes = [  
  // Auth Pages
  // {
  //   path: '/auth/verify',
  //   Component: lazy(() => import('components/Auth/Login')),
  //   exact: true,
  // },
  // {
  //   path: '/auth/login',
  //   Component: lazy(() => import('components/Auth/Login')),
  //   exact: true,
  // },
  // {
  //   path: '/auth/forgot-password',
  //   Component: lazy(() => import('components/Auth/ForgotPassword/ForgotPasswordStart')),
  //   exact: true,
  // },
  // {
  //   path: '/auth/register',
  //   Component: lazy(() => import('components/Auth/Register')),
  //   exact: true,
  // },
  //
  { 
    path: '/',
    Component: lazy(() => import('./pages/dashboard')),
    exact: true,
  },
  { 
    path: '/users',
    Component: lazy(() => import('./pages/UserTabs')),
    exact: true,
  },
  { 
    path: '/billing',
    Component: lazy(() => import('./pages/BillingTabs')),
    exact: true,
  },
  // { 
  //   path: '/companies',
  //   Component: lazy(() => import('./pages/Company')),
  //   exact: true,
  // },
  // { 
  //   path: '/projects',
  //   Component: lazy(() => import('./pages/Project')),
  //   exact: true,
  // },
  // { 
  //   path: '/vendors',
  //   Component: lazy(() => import('./pages/Vendor')),
  //   exact: true,
  // },
  // { 
  //   path: '/expenses',
  //   Component: lazy(() => import('./pages/Expense')),
  //   exact: true,
  // },
  // { 
  //   path: '/dashboard',
  //   Component: lazy(() => import('pages/dashboard')),
  //   exact: true,
  // },
  // {
  //   path: '/policies-terms/',
  //   Component: lazy(() => import('pages/policies-terms')),
  //   exact: true,
  // },
  { 
    path: '/404',
    Component: lazy(() => import('./pages/system/404')),
    exact: true,
  },
  // {
  //   path: '/500',
  //   Component: lazy(() => import('pages/system/500')),
  //   exact: true,
  // },
]
const routerAnimation = 'slide-fadein-down';
// const mapStateToProps = ({ settings }) => ({ settings })

type Props = {
  history: History<any>;
};

// console.log('history',history);
const Router: React.FC<Props> =(props: Props) =>{
  return (
    <ConnectedRouter history={props.history}>
      <Layout>
      <Suspense fallback={<Spin />}>
        <Switch
          render={ (props:any) => { 
            const {
              children,
              location: { pathname },
            } = props;
            console.log('in switch rendering',props);
            return (
              <SwitchTransition>
                <CSSTransition
                  key={pathname}
                  classNames={routerAnimation}
                  timeout={300}
                >
                  {children}
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        >
          {/* <Route exact path="/" render={() => <Redirect to="/" />} /> */}
          
          {routes.map(({ path, Component, exact }) => (
            <Route path={path} key={path} exact={exact}>
              <Component />
            </Route>
          ))}
          {/* <Route component={NotFoundPage} /> */}
          
          
        </Switch>
        </Suspense>
      </Layout>
    </ConnectedRouter>
  )
}
export default Router
