import React from 'react'
import { AmplifyAuthenticator, AmplifySignUp, AmplifySignIn, AmplifyConfirmSignIn, 
    AmplifyVerifyContact, AmplifyConfirmSignUp, AmplifyForgotPassword, AmplifyRequireNewPassword } from '@aws-amplify/ui-react';
    import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';

import './style.scss';
import Auth from '@aws-amplify/auth';
import { store } from 'index';
import { useDispatch } from 'react-redux';

const AmplifyAuth: React.FC = () => {
    const [authState, setAuthState] = React.useState();
    const [user, setUser] = React.useState();
    const dispatch = useDispatch()

    // React.useEffect(() => {
    //     return onAuthUIStateChange((nextAuthState:any, authData:any) => {
    //         setAuthState(nextAuthState);
    //         setUser(authData);
    //         console.log('onAuthUIStateChange',authData);
    //     });
    // }, []);

    const handleAuthStateChange = async (nextAuthState:any, authData:any) => {
        if (nextAuthState === AuthState.SignedIn) {
          console.log("user successfully signed in!");
          console.log("user data: ", authData);
          const currentSession = await Auth.currentSession();
          const currentUser = await Auth.currentAuthenticatedUser();
          console.log('current Session', currentSession.getIdToken().payload );
          dispatch({
              type: 'profile/setProfile',
              payload: { ...currentSession.getIdToken().payload, ...currentUser['attributes'] }
          });
        }
        if (authData) {
          console.log("authData: ", authData);
        }
      };

    return (
        <AmplifyAuthenticator usernameAlias="email"  handleAuthStateChange={handleAuthStateChange}>
            <AmplifySignUp
                slot="sign-up"
                usernameAlias="email"
                formFields={[
                    {
                        type: "custom:organization",
                        label: "Organization",
                        placeholder: "",
                        required: true,
                    },
                    {
                        type: "email",
                        label: "Email",
                        placeholder: "Email",
                        required: true,
                    },
                    {
                        type: "password",
                        label: "Password",
                        placeholder: "Password",
                        required: true,
                    },
                    // {
                    //   type: "phone_number",
                    //   label: "Custom Phone Label",
                    //   placeholder: "custom Phone placeholder",
                    //   required: false,
                    // },
                ]}
            />
            <AmplifyConfirmSignUp slot="confirm-sign-up"/>
            <AmplifySignIn slot="sign-in" usernameAlias="email" />
            <AmplifyConfirmSignIn slot="confirm-sign-in"/>
            <AmplifyVerifyContact slot="verify-contact	"/>
            <AmplifyForgotPassword slot="forgot-password"/>
            <AmplifyRequireNewPassword />
        </AmplifyAuthenticator>
    )
};
export default AmplifyAuth;