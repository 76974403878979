import React, { Fragment, useEffect } from 'react'
import { RouteComponentProps, withRouter, Redirect } from "react-router-dom";
import { connect, useSelector } from 'react-redux'
import NProgress from 'nprogress'
import { Helmet } from 'react-helmet'
import { Spin } from 'antd';
import PublicLayout from './Public'
import AuthLayout from './Auth'
import Main from './Main'
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';

const Layouts: any = {
  public: PublicLayout,
  auth: AuthLayout,
  app: Main,
  // plans:PlansLayout
}

const Layout = ({ location, children }: any) => {
  const [authState, setAuthState] = React.useState<AuthState>();
  const [user, setUser] = React.useState<object | undefined>();

  React.useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData);
      console.log('nextAuthState, authData', nextAuthState, authData);
    });
  }, []);

  const { pathname } = location;
  useEffect(() => {
    NProgress.start()
    setTimeout(() => {
      NProgress.done()
    }, 300)
  }, [pathname]);
  
  // Layout Rendering
  const getLayout = () => {
    if (/^\/ar(?=\/|$)/i.test(pathname) || /^\/policies-terms(?=\/|$)/i.test(pathname) || /^\/foodics/i.test(pathname)) {
      return 'public'
    }
    if (/^\/plans(?=\/|$)/i.test(pathname) || /^\/checkout(?=\/|$)/i.test(pathname) || /^\/contact(?=\/|$)/i.test(pathname) || /^\/payment-processing(?=\/|$)/i.test(pathname) || /^\/renew(?=\/|$)/i.test(pathname)) {
      return 'plans'
    }
    if (/^\/auth(?=\/|$)/i.test(pathname)) {
      return 'auth'
    }
    return 'app'
  }


  const Container = Layouts[getLayout()]
  const isUserAuthorized = (authState === AuthState.SignedIn && user) ? true : false;//user.authorized
  const isUserLoading = false;//(authState === AuthState.Loading || authState===undefined) ? true : false;//user.loading
  const layout = getLayout()
  const isAuthLayout = layout === 'auth'
  const isPublicLayout = layout === 'public'

  console.log('main ui');
  const BootstrappedLayout = () => {
    // show loader when user in check authorization process, not authorized yet and not on login pages
    if (isUserLoading && !isUserAuthorized && !isAuthLayout && !isPublicLayout) {
      return <Spin />
    }
    // // redirect to login page if current is not login page and user not authorized
    if (!isAuthLayout && !isUserAuthorized && !isPublicLayout) {
      // return <Redirect to="/auth/login" />
      return <AuthLayout ></AuthLayout>
    }
    // in other case render previously set layout
    return <Container>{children}</Container>
  }

  return (
    <Fragment>
      <Helmet titleTemplate="AppTax" title="AppTax" />
      {BootstrappedLayout()}
    </Fragment>
  )
}
export default withRouter(Layout);
