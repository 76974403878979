import { ListUsersQuery } from "../API";
import { GraphQLResult } from "@aws-amplify/api";

interface User {
  id?: string;
  email?: string;
  // organization: string;
  organizationId?: string;
  // #project: [Project]
  role?: string[];
  createdAt?: string;
}
interface UserList {
  items: [User];
  // totalCount: number;
  nextToken: any
}

function mapListUsersQuery(listUsersQuery: GraphQLResult<ListUsersQuery>): UserList {
  const items = listUsersQuery.data?.listUsers?.items?.map(user => ({
    id: user?.id,
    organizationId: user?.organizationId,
    role: user?.role,
    email: user?.email,
    createdAt: user?.createdAt,
  } as User)) || [];
  const nextToken = listUsersQuery.data?.listUsers?.nextToken;
  return {
    items: items,
    nextToken: nextToken
  } as UserList;
}


export default User;
export { mapListUsersQuery }