export default function getMenuData(): any[] {
  return [
    // {
    //   category: true,
    //   title: 'Dashboards',
    // },
    {
      title: 'Profile',
      key: 'userManagement',
      icon: 'TeamOutlined',
      roles:['admin','owner','member'],
    },
    {
      title: 'Applications',
      key: 'applications',
      icon: 'WindowsOutlined',
      roles:['admin','owner','member'],
    },
    {
      title: 'User Management',
      key: 'user',
      icon: 'TeamOutlined',
      roles:['admin','owner'],
      children: [{
        title: 'Users',
        key: 'user/users',
        link:'/users'
      }, {
        title: 'Role Management',
        key: 'user/role'
      }
      ]
    },
    {
      title: 'Account Management',
      key: 'account',
      icon: 'TeamOutlined',
      roles:['admin','owner'],
      children: [{
        title: 'Account Profile',
        key: 'account/profile'
      }, {
        title: 'Billing',
        key: 'account/billing'
      }, {
        title: 'Reports',
        key: 'account/reports'
      }
      ]
    }

  ]
}
