/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    // "aws_cognito_identity_pool_id": "us-east-1:1f048fac-aee7-4737-8994-4ba5bead7a3c",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_JxsLt9pDQ",
    "aws_user_pools_web_client_id": "1n9nckgi8g05hnhe6n5q14ol8",
    // oauth:{
    //     domain: 'auth.dev.apptax.io',
    //     scope: [ 
    //         'phone',
    //     'email',
    //     'openid',
    //     'aws.cognito.signin.user.admin',
    //     'profile'
    // ],
    //     redirectSignIn: 'http://localhost:3000',
    //     redirectSignOut: 'http://localhost:3000',
    //     responseType: 'code', // or token
    // },
    "aws_appsync_graphqlEndpoint": "https://uiexbcws7rhrtduke4jggg6u5m.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "null",
    "aws_content_delivery_bucket": "apptax-20210714162803-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "http://apptax-20210714162803-hostingbucket-dev.s3-website-us-east-1.amazonaws.com"
};


export default awsmobile;
